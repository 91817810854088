@import "../../../assets/scss/variables.scss";
.lineSeparator{
    width: 98%;
    text-align: center;
}
.sectionTitle{
    font-size: 1.625rem;
    color: $col_black;
    margin: 0 auto;
    width: calc(100% - (var(--item-margin) * 2));
}
.emptyMsg{
    margin: 10px 0;
    p{
        font-size: 1.15rem;
    }
}