@import "./modus/index.scss";
@import "./variables.scss";
@import "./modus/modus-override.scss";

@font-face {
  font-family: 'open_sansregular';
  src: url('./../fonts/opensans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@keyframes clicked-keyframes {
  from {
    opacity: 0.01
  }

  to {
    opacity: 1
  }
}


html {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: 'open_sansregular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  font-size: $default-font-size;
  background-color: #fafafa;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.shadow-white {
  box-shadow: 0 3px 6px $transparent_white, 0 3px 6px $transparent_black;
}

.cursor-pointer {
  cursor: pointer;
}

.text-decoration-none {
  text-decoration: none;
}

div,
img,
span,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  animation: clicked-keyframes 0.4s;
  -webkit-animation: clicked-keyframes 0.4s;
  animation-timing-function: ease-in;
}
:root{
  --items-per-row: 5;
  --item-margin: 16px;
  --item-max-width: 455px;
}