@import "../../../../assets/scss/variables.scss";
.dropdown {
    button {
        border-radius: 50% !important;
        -webkit-border-radius: 50% !important;
        -moz-border-radius: 50% !important;
        -ms-border-radius: 50% !important;
        -o-border-radius: 50% !important;

        i {
            color: $gray_dove;
        }

        &.open.btn-text-primary {
            color: $blue_regal;
            background-color: $col_trimble_blue_mid;
            opacity: 0.3;
        }
    }

    button.open {
        pointer-events: none;
    }

    .dropdown-menu {
        border: none;
        min-width: 10rem;

        &:focus {
            outline: none;
        }

        .dropdown-item {
            padding: 0.5em 1em;
            font-size: 14px !important;

            &.clickable {
                cursor: pointer;
            }

            &:not(.clickable) {
                pointer-events: none;

                &:focus,
                &:hover {
                    background-color: #fff;
                }
            }

            &:not(:first-child) {
                // border-top: 1px solid #d2d2d2;
            }
        }
    }
}
