// Modus functions
@import "~@trimbleinc/modus-bootstrap/scss/components/functions";

// Modus variables
@import "~@trimbleinc/modus-bootstrap/scss/components/trimble-colors";
@import "~@trimbleinc/modus-bootstrap/scss/components/dark-theme";
@import "~@trimbleinc/modus-bootstrap/scss/components/modus-variables";

$default-font-size: 14px;

//Missing color codes from _trimble-colors.scss
$gray_emperor: #545454;
$gray_dove: #707070;
$transparent_white: #ffffff29;
$transparent_black: #0000003b;
$blue_regal: #00406b;
$blue_cyan: #00559b;
$col_trimble_blue_mid: #005F9E;