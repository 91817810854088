@import "../../../../assets/scss/variables.scss";

.appCard {
  @media (max-width: 320px) {
    --items-per-row: 1;
    height: 174px;
  }
  @media (min-width: 320.1px) and (max-width:400px) {
    --items-per-row: 2;
  }
  @media (min-width: 400.1px) and (max-width: 576px) {
    --items-per-row: 3;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576.1px) and (max-width: 768px) {
    --items-per-row: 2;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768.1px) and (max-width: 992px) {
    --items-per-row: 3;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992.1px) and (max-width: 1920px) {
    --items-per-row: 4;
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 576px) {
    .card-body{
      justify-content: center;
      align-items: center;
      .card-text, .appActionBtn, .dropdownHolder {
        display: none!important;
      }
      .appDisplay{
        flex-direction: column;
      }
      .appName {
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 8px;
      }
      .logoIcon {
        margin-right: 0!important;
      }
    }
  }
  width: calc(100% / var(--items-per-row) - var(--item-margin));
  .card{
    height: 100%;
    width: 100%;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    .card-subtitle,
    .card-text {
      font-size: $default-font-size;
    }

    .card-subtitle {
      flex-grow: 0;


      .logoIcon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      .dropdownHolder {
        margin-right: -15px;
      }
    }

    .card-text {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      flex-grow: 1;
      margin-top:24px;
    }

    .card-text,
    .appName {
      color: $gray_emperor;
    }
    
    .appActionBtn {

      &.btnDemo {
        color: $col_gray_6;
        fill: $col_gray_6;
        border-color: $col_gray_6;

        &:hover {
          color: $col_white;
          background-color: $col_gray_6;
          border-color: $col_gray_6;
        }
      }
      .blue-text{
        color: $blue_cyan;
      }
    }
  }
}
