@import "../../../../assets/scss/variables.scss";

.banner {
  width: auto;
  display: inline-block;
  position: relative;
  max-height: 336px;
  .carouselContainer{
    height: 100%;
    max-height: 336px;
    overflow: hidden;
    position: relative;
    .sliderDots{
      position: absolute;
      top: 290px;
      width: 100%;
      text-align: center;
      button{
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border-width: 1px;
        background-color: $col_gray_6;
        margin-left: 10px;
        border-style: none;
        outline: none;
        &:disabled{
          background-color: $col_white;
        }
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    max-height: 336px;
    z-index: 1;
    object-fit: cover;
  }
  .bannerContent{
    position: absolute;
    z-index: 3;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 10%;
    align-items: center;
    .bannerTitle{
      font-size: 3rem;
      color: $col_trimble_blue_dark;
      font-weight: bold;
    }
  }
  .bannerOverlay{
    position: absolute;
    z-index: 2;
    background: linear-gradient(to right, $col_white, transparent);
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    display: flex;
    
  }
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 576px) {
    display: none;
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (max-width: 768px) {
    .bannerTitle{
      font-size: 37px!important;
      width: 311px;
    }
  }
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1920.1px){
    max-height: 464px!important;
  }
}
